import React from "react";
import "./styles.scss";

export const Header: React.FC = () => {
  return (
    <nav
      className="navbar navbar-light navbar-expand-md justify-content-between aos-init aos-animate"
      data-aos="fade-down"
    >
      <div className="container">
        <button
          data-bs-toggle="collapse"
          className="navbar-toggler align-items-center header__toggle"
          data-bs-target="#navbarSupportedContent"
        >
          <span className="visually-hidden">Toggle navigation</span>
          <span className="navbar-toggler-icon"></span>
        </button>
          <h1 className="navbar-toggler navbrand">
            Anon<span style={{color: "#808184"}}>pepe</span>
          </h1>
        <div id="navbarSupportedContent" className="collapse navbar-collapse">
          <h1 className="navbrand">
            Anon<span style={{color: "#808184"}}>pepe</span>
          </h1>
          <ul className="navbar-nav content">
            <li>
              <a className="nav-links" href="#features">
                Features
              </a>
            </li>
            <li>
              <a className="nav-links" href="#roadmap">
                Roadmap
              </a>
            </li>
            {/* <li>
              <a className="nav-links" href="#tokenomics">
                Tokenomics
              </a>
            </li> */}
          </ul>
          <ul className="ms-auto mb-auto d-flex">
            <a href="/#">
              <button className="navbutton m-0">Comming soon</button>
            </a>
          </ul>
        </div>
      </div>
    </nav>
  );
};
