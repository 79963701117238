import React from 'react';
import './App.css';
import { Header } from './components/header';
import { Introduction } from './components/introduction';
import { DiscoverOurFeatures } from './components/discover-our-features';
import { MoreDetails } from './components/more-details';
// import { Tokenomics } from './components/tokenomics';
import { Footer } from './components/footer';
import { Timeline } from './components/timeline';

function App() {
  return (
    <div>
      <Header />
      <Introduction />
      <main className='features' id="features">
        <DiscoverOurFeatures />
        <MoreDetails />
        <Timeline />
        {/* <Tokenomics /> */}
      </main>
      <Footer />
    </div>
  );
}

export default App;
