import React from "react";

const dextoolUrl = `https://www.dextools.io/app/en/solana/pair-explorer/Db5aDVsivFpAr7oc3c4wSD6QNkdEdGr4x5outN6TLPoq`

export const Introduction: React.FC = () => {
  return (
    <header style={{ marginTop: "100px" }} className="content">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-6">
            <p
              className="header-sub aos-init"
              data-aos="fade-up"
              data-aos-delay="100"
            ></p>
            <h1
              className="header-main m-0 aos-init"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              The First <span>Security</span> DApp to Safeguard Pepe and Other Memes is Here!
            </h1>
            <p
              className="header-desc aos-init"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              Are you sick of seeing your favorite memes, like Pepe, taken or used inappropriately? Look nowhere else! <br/> With the help of blockchain technology, your memes can be protected with the help of our ground-breaking security DApp.
            </p>
            <div>
              <a href={dextoolUrl}>
                <button
                  className="main-btn aos-init"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  Buy $APE
                </button>
              </a>
            </div>
            <div
              className="d-flex headersocials aos-init"
              data-aos="fade-up"
              data-aos-delay="600"
              style={{ marginTop: "65px" }}
            >
              <h3 className="connect ">Connect with us:</h3>
              <div>
                <a href="https://t.me/APEPORTALSOL">
                  <img alt="logo"
                    className="social"
                    src="./assets/img/socials/telegram.svg"
                  />
                </a>
                <a href="https://twitter.com/ANONPEPESOL">
                  <img alt="logo"
                    className="social"
                    src="./assets/img/socials/twitter (1).svg"
                  />
                </a>
                <a href={dextoolUrl}>
                  <img alt="logo"
                    className="social"
                    src="./assets/img/socials/Group 38.svg"
                  />
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-sm-12 col-lg-6 text-end aos-init aos-animate illustration-wrapper"
            data-aos="fade-in"
          >
            <img alt="logo"
              className="illustration"
              style={{
                animation: "float 3s ease-in-out infinite",
              }}
              src="./assets/img/illustrations/pepe.png"
            />
          </div>
        </div>
      </div>
    </header>
  );
};
