import React from "react";

export const MoreDetails: React.FC = () => {
  return (
    <div className="container" style={{ marginTop: "150px" }}>
      <div className="row">
        <div
          className="col-sm-12 col-lg-6 d-flex gap-5 aos-init aos-animate"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="greenfade"></div>
          <div>
            <h1 className="section-header">Blackhole Mixer</h1>
            <p className="header-desc mb-0">
            Blackhole Mixer is a privacy tool known as a mixer that we created to boost anonymity by utilizing zk-SNARKs technology.
            </p>
            <p className="header-desc mb-0">
            AnonPeper can transfer and receive cryptocurrency anonymously while maintaining their identity and transaction history concealed thanks to this privacy breaking through technology.
            </p>
          </div>
        </div>
        <div
          className="d-sm-none col-sm-12 col-lg-6 aos-init aos-animate"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <img alt="logo"
            className="illustration"
            style={{
              marginTop: `-10rem; animation: float 3s ease-in-out infinite;" "="`,
            }}
            src="./assets/img/illustrations/mixer.svg"
          />
        </div>
      </div>
    </div>
  );
};
