import React from "react";

export const DiscoverOurFeatures: React.FC = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <h1
            className="section-header aos-init aos-animate"
            data-aos="fade-up"
          >
            Discover our <span>Features</span>
          </h1>
          <p
            className="section-sub aos-init aos-animate"
            data-aos="fade-up"
            style={{ marginBottom: "150px" }}
          >
            We're not limited to the features below as our privacy ecosystem
            grows
          </p>
        </div>
        <div
          className="col-sm-12 col-lg-6 mb-4 aos-init aos-animate"
          data-aos="fade-up"
        >
          <div className="main-div d-flex align-items-center gap-5">
            <img alt="logo"
              className="feature-icon"
              src="./assets/img/icons/Mask Group 5.png"
            />
            <div>
              <h1 className="feature-title">Zero-knowledge Proof: zk-SNARKS</h1>
              <p className="feature-desc mb-0">
              By using zk-SNARKs, we may validate a transaction's authenticity without disclosing any sender or recipient information.
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-sm-12 col-lg-6 mb-4 aos-init"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="main-div d-flex align-items-center gap-5">
            <img alt="logo"
              className="feature-icon"
              src="./assets/img/icons/Mask Group 5.png"
            />
            <div>
              <h1 className="feature-title">Privacy matters</h1>
              <p className="feature-desc mb-0">
              Our expanding, privacy-focused ecosystem will be strengthened by all recent and upcoming advances.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
