import React from "react";
import "./styles.scss";

export const Timeline: React.FC = () => {
  return (
    <div className="roadmap" id="roadmap">
      <h1
        className="section-header aos-init aos-animate"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        Roadmap
      </h1>
      <div
        className="timeline container aos-init aos-animate"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        <div className="timeline__container timeline__right timeline__done">
          <div className="timeline__content">
            <h2>01.</h2>
            <p>Deploy $APE Token on Solana</p>
          </div>
        </div>
        <div className="timeline__container timeline__left timeline__done">
          <div className="timeline__content">
            <h2>02.</h2>
            <p>Release project Roadmap</p>
          </div>
        </div>
        <div className="timeline__container timeline__right timeline__done">
          <div className="timeline__content">
            <h2>03.</h2>
            <p>Get listed on CG and CMC</p>
          </div>
        </div>
        <div className="timeline__container timeline__left timeline__done">
          <div className="timeline__content">
            <h2>04.</h2>
            <p>Develop project website</p>
          </div>
        </div>
        <div className="timeline__container timeline__right">
          <div className="timeline__content">
            <h2>05.</h2>
            <p>Deploy relayers for Blackhole mixer</p>
          </div>
        </div>
        <div className="timeline__container timeline__left">
          <div className="timeline__content">
            <h2>06.</h2>
            <p>Deploy Mixer on Shibarium Mainnet</p>
          </div>
        </div>
        <div className="timeline__container timeline__right">
          <div className="timeline__content">
            <h2>07.</h2>
            <p>Add arbitrary amounts to mixer (custom amounts)</p>
          </div>
        </div>
        <div className="timeline__container timeline__left">
          <div className="timeline__content">
            <h2>08.</h2>
            <p>Look to add support for standalone ERC20 tokens</p>
          </div>
        </div>
        <div className="timeline__container timeline__right">
          <div className="timeline__content">
            <h2>09.</h2>
            <p>
              Reach out and establish partnerships with projects that share
              similar interests
            </p>
          </div>
        </div>
        <div className="timeline__container timeline__left">
          <div className="timeline__content">
            <h2>10.</h2>
            <p>
              Continue to look for new utilities to develop & listen to DAO
              feedback
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
